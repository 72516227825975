import React from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { FaMailBulk, FaUserFriends } from 'react-icons/fa';
import IconSmartAction from '@/assets/icons/IconSmartAction';
import NeighborhoodIcon from '@/assets/icons/NeighborhoodIcon';

import { QrcodeOutlined } from '@ant-design/icons';
import RoutePaths from './routes';

export const userMenuItems = {
  MANAGER: [
    {
      to: RoutePaths.CAMPAIGNS + '/?tab=CAMPAIGNS_FORM',
      name: 'Campaigns',
      icon: '',
    },
    {
      to: RoutePaths.SETTINGS,
      name: 'Settings',
      icon: '',
    },
    {
      to: RoutePaths.LOGOUT,
      name: 'Log Out',
      icon: <span></span>,
    },
  ],
  AGENT: [
    {
      to: RoutePaths.AGENT_REPORT,
      name: 'Agent Reports',
      icon: <NeighborhoodIcon />,
    },
    {
      to: RoutePaths.CAMPAIGNS + '/?tab=CAMPAIGNS_FORM',
      name: 'Campaigns',
      icon: '',
    },
    {
      to: RoutePaths.MAILING_LISTS,
      name: 'Mailing Lists',
      icon: <AiOutlineMail />,
    },
    {
      to: RoutePaths.MY_NFT,
      name: 'My NFTs',
      icon: '',
    },
    {
      to: RoutePaths.DRIP_CAMPAIGNS,
      name: 'Drip Campaigns',
      icon: <FaMailBulk />,
    },
    {
      to: RoutePaths.CREATE_QR_CODE,
      name: 'Create QR Code ',
      icon: <QrcodeOutlined />,
    },
    {
      to: RoutePaths.SMART_ACTIONS + `/?tab=SMART_ACTIONS`,
      name: 'Smart Actions',
      icon: <IconSmartAction />,
    },
    {
      to: RoutePaths.SETTINGS,
      name: 'Settings',
      icon: '',
    },
    {
      to: RoutePaths.LOGOUT,
      name: 'Log Out',
      icon: '',
    },
  ],
  BROKER: [
    {
      to: RoutePaths.BROKER_MY_AGENTS,
      name: 'My Agents',
      icon: <FaUserFriends />,
    },
    {
      to: RoutePaths.SETTINGS,
      name: 'Settings',
      icon: '',
    },
    {
      to: RoutePaths.LOGOUT,
      name: 'Log Out',
      icon: '',
    },
  ],
};

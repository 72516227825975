import * as React from 'react';
import { SVGProps } from 'react';

const IconSmartAction = (props: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...props}>
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      transform="translate(-2 -2)"
    >
      <circle cx="3" cy="3" r="3" data-name="Ellipse 21" transform="translate(15 15)"></circle>
      <circle cx="3" cy="3" r="3" data-name="Ellipse 22" transform="translate(3 3)"></circle>
      <path d="M13 6h3a2 2 0 012 2v7" data-name="Path 1029"></path>
      <path d="M0 0L0 12" data-name="Line 131" transform="translate(6 9)"></path>
    </g>
  </svg>
);

export default IconSmartAction;

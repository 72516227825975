import styled from '@emotion/styled';

const NavMobileViewStyled = styled.section`
  top: 114px;
  display: flex;
  overflow: auto;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex: 0 0 auto;
  position: initial;
  overflow: auto;
  flex-direction: row;
  width: 100%;

  nav {
    display: flex;

    > a,
    > div {
      display: flex;
      padding: 16px;
      align-items: stretch;
      flex: 0 0 auto;
      transition: all 400ms ease;
      background-color: #2e0249;
      cursor: pointer;
      color: #ffffff;
      padding: 10px 30px;
      align-items: center;
      max-width: 100%;
      border-left: none;

      &.active,
      &:hover {
        border-left: none;
        color: #fff;
        background-color: #e7edf3;
        .text-block,
        .title,
        .label-back-to-admin {
          color: #2e0249;
        }
      }

      &.button-container {
        display: flex;
        flex-direction: column;
      }

      .text-block {
        display: flex;
        align-items: center;
        flex-direction: column;
        color: #fff;

        &.use-info {
          font-size: 18px;
          font-weight: 600 !important;
          font-family: 'Fira Sans', sans-serif;
        }

        .img-user {
          width: 34px;
          height: 34px;
          border-radius: 50%;
          font-size: 12px !important;
        }

        span {
          margin-bottom: 12px;
        }

        > .fa-icon {
          display: inline;
          font-family: 'Fa 300', sans-serif;
          font-size: 24px;
          line-height: 1.2;
        }
      }
    }
  }
`;

export default NavMobileViewStyled;

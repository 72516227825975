import React, { FC } from 'react';
import MetamaskImage from '@/assets/images/metamask_fox.png';
import BaseModal from '@/components/Modal/index';
import { METAMASK_DOWNNLOAD_LINK } from '@/utils/constant';
import { useStyleModal } from './style';
import { usePastOrderStore } from '@/components/dashboard/hooks/usePastOrder';
import { useShallow } from 'zustand/react/shallow';
import AppButtonV2 from '@/components/AppButtonV2';

interface MetamaskNotFoundModalProps {
  visible?: boolean;
  onCancel?: () => void;
}

const MetamaskNotFoundModal: FC<MetamaskNotFoundModalProps> = ({ onCancel, visible, ...rest }) => {
  const { styleModal } = useStyleModal();
  const { isMetamaskNotFoundVisible, setIsMetamaskNotFoundModalVisible } = usePastOrderStore(
    useShallow((state) => ({
      isMetamaskNotFoundVisible: state.isMetamaskNotFoundVisible,
      setIsMetamaskNotFoundModalVisible: state.setIsMetamaskNotFoundModalVisible,
    })),
  );

  return (
    <div css={styleModal}>
      <BaseModal
        getContainer={false}
        visible={visible ?? isMetamaskNotFoundVisible}
        onCancel={() => {
          if (onCancel) onCancel();
          else setIsMetamaskNotFoundModalVisible(false);
        }}
        footer={false}
        centered
        {...rest}
      >
        <img src={MetamaskImage} alt="metamask" />
        <h2>Metamask Not Found</h2>
        <p>It seems that you have not installed Metamask wallet. Please install now.</p>
        <div className="install-metamask">
          <AppButtonV2 size="large" width="100%" color="Pin" href={METAMASK_DOWNNLOAD_LINK} target="_blank">
            INSTALL METAMASK
          </AppButtonV2>
        </div>
      </BaseModal>
    </div>
  );
};

export default MetamaskNotFoundModal;

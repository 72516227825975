import { css } from '@emotion/react';
import { message } from 'antd';
import { navigate } from 'gatsby';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { AlertTextError, showPopup } from '@/components/alert/SweetAlert';
import { StorageKeys } from '@/utils/enum';
import RoutePaths from '@/utils/routes';

const ExitView: React.FC = () => {
  const isViewAgentByBroker = localStorage.getItem(StorageKeys.BROKERAGE_TOKEN);

  const handleExitAndGoBackBrokerage = () => {
    showPopup(
      {
        message: `Are you sure you want to go back Admin view?`,
        title: `Confirmation`,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      },
      async (value) => {
        if (value && value.isConfirmed) {
          const brokerAccessToken = localStorage.getItem(StorageKeys.BROKERAGE_TOKEN);
          if (!brokerAccessToken) {
            AlertTextError('Action Failed', 'Cannot go back to Brokerage View at the moment!', () => {
              navigate(RoutePaths.LOGOUT);
            });
            return;
          }
          localStorage.setItem(StorageKeys.SESSION_KEY, brokerAccessToken);
          localStorage.removeItem(StorageKeys.BROKERAGE_TOKEN);
          message.loading('Please wait for refetching Admin data view....', 2, () => {
            window.location.href = RoutePaths.BROKER_MY_AGENTS;
          });
        }
      },
    );
  };

  if (!isViewAgentByBroker) return null;
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: 16px;
        gap: 4px;
        .icon {
          color: #ff00dd;
          z-index: 99999;
          width: 40px;
          height: 40px;

          /* box-shadow: 0px 0px 10px 0px rgba(44, 23, 115, 0.5); */
          border-radius: 4px;
        }
        .label {
          color: #fff;
          font-weight: 500;
        }
      `}
      onClick={handleExitAndGoBackBrokerage}
    >
      <IoArrowBackCircleOutline className="icon" />
      <span className="label label-back-to-admin">Back To Admin</span>
    </div>
  );
};

export default ExitView;

import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { useMemo } from 'react';
import { getErrorConnectMessage, injected, walletConnect } from '@/config/connector';

export const useConnectWallet = () => {
  const { activate, deactivate } = useWeb3React();
  const connect = useMemo(() => {
    return {
      connectInjected(metamaskNotFound?: any, callbackSuccess?: any, callbackError?: any): void {
        injected.isAuthorized().then(async () => {
          await activate(injected, undefined, true).catch((error) => {
            callbackError && callbackError();
            getErrorConnectMessage(error, metamaskNotFound);
          });
          callbackSuccess && callbackSuccess();
        });
      },

      connectWalletConnect(): void {
        if (walletConnect instanceof WalletConnectConnector && (walletConnect.walletConnectProvider as any)?.wc?.uri) {
          walletConnect.walletConnectProvider = undefined;
        }
        walletConnect &&
          activate(walletConnect, undefined, true).catch(async (error) => {
            deactivate();
            getErrorConnectMessage(error);
            if (error instanceof UnsupportedChainIdError) {
              deactivate();
            }
          });
      },

      connectDeactivate(): void {
        deactivate();
      },
    };
  }, []);

  return connect;
};

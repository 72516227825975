import React, { FC, ReactNode } from 'react';
import { useStyleModal } from './style';
import BaseModal from '@/components/Modal/index';
import Loading from '@/assets/images/loading-icon.svg';
import Wallet from '@/assets/images/wallet.svg';
import { usePastOrderStore } from '@/components/dashboard/hooks/usePastOrder';
import { useShallow } from 'zustand/react/shallow';

interface LoadingModalProps {
  visible?: boolean;
  title: string;
  content: string | ReactNode;
  processing?: boolean;
}

const LoadingModal: FC<LoadingModalProps> = ({ visible, title, content, processing, ...rest }) => {
  const { styleModal } = useStyleModal();
  const { isConnectingWalletModalVisible } = usePastOrderStore(
    useShallow((state) => ({
      isConnectingWalletModalVisible: state.isConnectingWalletModalVisible,
    })),
  );
  return (
    <div css={styleModal}>
      <BaseModal
        visible={visible ?? isConnectingWalletModalVisible}
        {...rest}
        getContainer={false}
        footer={false}
        centered
        closable={false}
      >
        <div className="modal-spin">
          <div className="modal-spin__wrapper">
            <img src={processing ? Loading : Wallet} alt="loading" className={processing ? 'rotating' : ''} />
          </div>
        </div>
        <h2>{title}</h2>
        {content}
      </BaseModal>
    </div>
  );
};

export default LoadingModal;

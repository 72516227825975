import { SidebarProps } from '@/models/SidebarProps';
import globalVariable from '@/config/env';
import { Tooltip } from 'antd';
import IconLogout from '@/assets/icons/IconLogout';
import { convertAddressWallet } from '@/utils/utils';
import Metamask from '@/assets/images/metamask_fox.png';
import AppButtonV2 from '@/components/AppButtonV2';
import { color } from '@/styles/theme';

interface ConnectWalletButtonProps extends SidebarProps {
  active: boolean;
  chainId: number;
  account: string;
}

const ConnectWalletButtonView: React.FC<ConnectWalletButtonProps> = ({
  active,
  connectMetamask,
  chainId,
  isAddressCopied,
  copyToClipBoard,
  account,
  disconnectMetamask,
}) => {
  return (
    <AppButtonV2
      size="middle"
      color="Outline"
      width="200px"
      roundedFull
      outlineColor={color.PINK}
      uppercase={false}
      style={{ margin: '0 auto' }}
      fontSize={14}
      gap={5}
      icon={<img src={Metamask} width={28} height={28} alt="icon" />}
      onClick={!active ? connectMetamask : undefined}
    >
      {active && chainId === globalVariable.POLYGON_CHAINID ? (
        <div className="btn-connect__address">
          <Tooltip title={isAddressCopied ? 'Copied' : 'Copy Address'}>
            <span onClick={copyToClipBoard}>{convertAddressWallet(account)}</span>
          </Tooltip>
          <div />
          <Tooltip title="Disconnect Wallet">
            <IconLogout onClick={disconnectMetamask} />
          </Tooltip>
        </div>
      ) : (
        'Connect Wallet'
      )}
    </AppButtonV2>
  );
};

export default ConnectWalletButtonView;

import { useWeb3React } from '@web3-react/core';
import { Image } from 'antd';
import { Link } from 'gatsby';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import UserAvatar from '@/components/Avatar';
import { useScrollNav } from '@/hooks/useScrollNav';

import { RootState } from '@/reducers/model';
import { UserType } from '@/utils/enum';
import RoutePaths from '@/utils/routes';
import ExitView from '@/components/exit-view';
import { SidebarProps } from '@/models/SidebarProps';
import NavMobileViewStyled from './NavMobileViewStyled';
import { userMenuItems } from '@/utils/menuLayout';
import CreditAndHomeAmount from '../CreditAndHomeAmount';
import ConnectWalletButton from '../ConnectWalletButton';
const NavMobileView: React.FC<SidebarProps> = ({
  disconnectMetamask,
  connectMetamask,
  isAddressCopied,
  copyToClipBoard,
}) => {
  const { getIdActive } = useScrollNav();
  const myProfile = useSelector((state: RootState) => state?.auth?.myProfile);
  const dataAgent = useSelector((state: RootState) => state?.home?.userAgent);
  const mailingListsCombine = useSelector((state: RootState) => state.mailing.mailingListsCombine);

  const { active, account, chainId } = useWeb3React();
  const fullName = useMemo(() => {
    return [myProfile?.first_name, myProfile?.last_name].filter(Boolean).join(' ');
  }, [dataAgent]);
  return (
    <NavMobileViewStyled>
      <nav>
        <div className="button-container">
          {isEmpty(dataAgent) ? (
            <Link id={getIdActive(RoutePaths.DASHBOARD)} to={RoutePaths.DASHBOARD} activeClassName="active">
              <div className="text-block use-info">
                {myProfile?.avatar ? (
                  <Image className="img-user" src={myProfile?.avatar} preview={false} />
                ) : (
                  <UserAvatar
                    name={fullName}
                    src={myProfile?.avatar}
                    color="#2e0249"
                    size={130}
                    radius={1000}
                    className="img-user"
                    backgroundColor="#e1e1df"
                  />
                )}
                <span>{fullName}</span>
              </div>
            </Link>
          ) : (
            <Link id={getIdActive(RoutePaths.DASHBOARD)} to={RoutePaths.DASHBOARD} activeClassName="active">
              <div className="text-block use-info">
                <UserAvatar
                  name={fullName}
                  src={myProfile?.avatar}
                  color="#2e0249"
                  size={34}
                  radius={1000}
                  className="img-user"
                  backgroundColor="#e1e1df"
                />
                <span>{fullName}</span>
              </div>
            </Link>
          )}
          <ConnectWalletButton
            active={active}
            account={account as string}
            chainId={chainId as number}
            connectMetamask={connectMetamask}
            disconnectMetamask={disconnectMetamask}
            copyToClipBoard={copyToClipBoard}
            isAddressCopied={isAddressCopied}
          />
          <CreditAndHomeAmount />
          <ExitView />
        </div>
        {userMenuItems[myProfile?.user_type as UserType].map((item, i) => {
          const toPath =
            item.to === RoutePaths.AGENT_REPORT && mailingListsCombine?.results[0]?.id
              ? `${item.to}/${mailingListsCombine?.results[0]?.id}`
              : item.to;
          let pathname = window.location.pathname;
          if (pathname !== '/') {
            pathname = pathname.replace(/\/$/, '');
          }
          const isActive = toPath?.includes(pathname);
          return (
            <Link
              to={toPath}
              key={i}
              id={getIdActive(toPath)}
              className={`${isActive ? 'active' : ''}`}
              activeClassName="active"
              partiallyActive
            >
              <div className="text-block">
                <span className="fa-icon">{item.icon}</span>
                {item.name}
              </div>
            </Link>
          );
        })}
      </nav>
    </NavMobileViewStyled>
  );
};

export default NavMobileView;

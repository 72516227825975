import { create } from 'zustand';

type DataInteractionType = {
  visible: boolean;
  data: Array<any>;
};

interface PastOrderStore {
  selectedCampaign: any;
  setSelectedCampaign: (selectedCampaign: any) => void;
  dataInteractions: Partial<DataInteractionType>;
  setDataInteractions: (data: Partial<DataInteractionType>) => void;
  isMetamaskNotFoundVisible: boolean;
  setIsMetamaskNotFoundModalVisible: (visible: boolean) => void;
  isMetamaskNotConnectedVisible: boolean;
  setIsMetamaskNotConnectedModalVisible: (visible: boolean) => void;
  isConnectingWalletModalVisible: boolean;
  setIsConnectingWalletModalVisible: (visible: boolean) => void;
}

export const usePastOrderStore = create<PastOrderStore>((set) => {
  return {
    selectedCampaign: null,
    setSelectedCampaign: (value) => set({ selectedCampaign: value }),
    dataInteractions: { data: [], visible: false },
    setDataInteractions: (interactionValue) => set({ dataInteractions: interactionValue }),
    isMetamaskNotFoundVisible: false,
    setIsMetamaskNotFoundModalVisible: (visible) => set({ isMetamaskNotFoundVisible: visible }),
    isMetamaskNotConnectedVisible: false,
    setIsMetamaskNotConnectedModalVisible: (visible) => set({ isMetamaskNotConnectedVisible: visible }),
    isConnectingWalletModalVisible: false,
    setIsConnectingWalletModalVisible: (visible) => set({ isConnectingWalletModalVisible: visible }),
  };
});

import styled from '@emotion/styled';
import { Layout } from 'antd';

const LayoutDashboardStyled = styled(Layout)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  &.ant-layout {
    background: #e7edf3 !important;
  }
  .btn-connect {
    margin: auto;
    margin-bottom: 10px;
    border-width: 2px;
    border-radius: 21px;
    text-transform: initial;
    letter-spacing: initial;
    height: 42px;
    min-width: 191px;
    background-color: #2e0249 !important;
    span {
      margin-left: 8px;
    }
    &__address {
      display: flex;
      align-items: center;
      div {
        width: 2px;
        height: 42px;
        background-color: #ff00dd;
        margin-left: 12px;
        margin-right: 12px;
      }
    }
  }
  &.overflow-hidden {
    overflow: hidden;
  }
`;
export default LayoutDashboardStyled;

import styled from '@emotion/styled';

const CreditAndHomeAmountViewStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;

  .credit-amount,
  .home-amount {
    padding: 10px;
    color: #ff00dd;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .title {
      color: #fff;
      font-size: 14px;
    }
  }
`;

export default CreditAndHomeAmountViewStyled;

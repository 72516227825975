import { useSelector } from 'react-redux';
import CreditAndHomeAmountViewStyled from './CreditAndHomeAmountViewStyled';
import { RootState } from '@/reducers/model';
import { memo } from 'react';

const CreditAndHomeAmountView = memo(() => {
  const mailCreditBalance = useSelector((state: RootState) => state.credit.myCredit?.credit_amount || 0);
  const currentBalance = useSelector((state: RootState) => state.homeCredit?.mySub?.current_balance || 0);
  const isUnlimitedHome = useSelector((state: RootState) => state.auth.myProfile?.unlimited_home);

  return (
    <CreditAndHomeAmountViewStyled>
      <div className="credit-amount">
        <p className="title">{mailCreditBalance > 1 ? 'Campaign Credits' : 'Campaign Credit'}</p>
        <p>{mailCreditBalance}</p>
      </div>
      <div className="home-amount">
        <p className="title">{currentBalance > 1 ? 'Home Credits' : 'Home Credit'}</p>
        <p>{isUnlimitedHome ? 'Unlimit' : currentBalance}</p>
      </div>
    </CreditAndHomeAmountViewStyled>
  );
});

export default CreditAndHomeAmountView;

import { useLocation } from '@reach/router';
import { useWeb3React } from '@web3-react/core';
import { Image, Menu } from 'antd';
import { Link } from 'gatsby';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import UserAvatar from '@/components/Avatar';
import { RootState } from '@/reducers/model';
import { UserType } from '@/utils/enum';
import { sentenceCase } from '@/utils/format';
import RoutePaths from '@/utils/routes';
import { ManagerInsightTabsKey } from '@/models';
import ExitView from '@/components/exit-view';
import SimpleBar from 'simplebar-react';
import { SidebarProps } from '@/models/SidebarProps';
import { userMenuItems } from '@/utils/menuLayout';
import SideBarViewStyled from './SideBarViewStyled';
import ConnectWalletButton from '../NavBar/ConnectWalletButton';
import CreditAndHomeAmount from '../NavBar/CreditAndHomeAmount';

const SideBarView: React.FC<SidebarProps> = ({
  disconnectMetamask,
  connectMetamask,
  isAddressCopied,
  copyToClipBoard,
}) => {
  const location = useLocation();
  const myProfile = useSelector((state: RootState) => state?.auth?.myProfile);
  const dataAgent = useSelector((state: RootState) => state?.home?.userAgent);
  const mailingListsCombine = useSelector((state: RootState) => state.mailing.mailingListsCombine);
  const [errorAvatar, setErrorAvatar] = useState<boolean>(false);
  const { active, account, chainId } = useWeb3React();
  const avatarURLWithEmptyAgent =
    myProfile?.user_type === UserType.MANAGER
      ? `${RoutePaths.MANAGER}/?tab=${ManagerInsightTabsKey.INSIGHTS}`
      : myProfile?.user_type === UserType.AGENT
      ? RoutePaths.AGENT_REPORT
      : myProfile?.user_type === UserType.BROKER
      ? RoutePaths.BROKER_MY_AGENTS
      : RoutePaths.DASHBOARD;
  const avatarURLWithAgent =
    myProfile?.user_type === UserType.MANAGER
      ? `${RoutePaths.MANAGER}/?tab=${ManagerInsightTabsKey.INSIGHTS}`
      : mailingListsCombine?.results[0]
      ? `${RoutePaths.AGENT_REPORT}/${mailingListsCombine?.results[0]?.id}`
      : myProfile?.user_type === UserType.AGENT
      ? RoutePaths.AGENT_REPORT
      : myProfile?.user_type === UserType.BROKER
      ? RoutePaths.BROKER_MY_AGENTS
      : RoutePaths.DASHBOARD;

  const isBrokerage = myProfile?.user_type === UserType.BROKER;
  const ref = useRef<any>(null);

  useEffect(() => {
    ref.current.recalculate();
  });

  return (
    <SideBarViewStyled.Sider width={280}>
      <SimpleBar ref={ref} style={{ maxHeight: '100%' }}>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[location.pathname.replaceAll('/', '')]}>
          {isEmpty(dataAgent) ? (
            <Menu.Item className="user-info" style={{ pointerEvents: isBrokerage ? 'none' : 'auto' }}>
              <Link to={avatarURLWithEmptyAgent}>
                {myProfile?.avatar ? (
                  <Image className="img-user" src={myProfile?.avatar} preview={false} />
                ) : (
                  <UserAvatar
                    name={`${myProfile?.first_name} ${myProfile?.last_name}`}
                    src={myProfile?.avatar}
                    color="#2e0249"
                    size={130}
                    radius={1000}
                    className="img-user"
                    backgroundColor="#e1e1df"
                  />
                )}

                <h1>{`${myProfile?.first_name} ${myProfile?.last_name}`}</h1>
                <p>{`${sentenceCase(myProfile?.user_type || '')}`}</p>
              </Link>
              <div className="tab-active"></div>
            </Menu.Item>
          ) : (
            <Menu.Item className="user-info" style={{ pointerEvents: isBrokerage ? 'none' : 'auto' }}>
              <Link to={avatarURLWithAgent}>
                <UserAvatar
                  name={`${myProfile?.first_name} ${myProfile?.last_name}`}
                  src={!errorAvatar ? myProfile?.avatar : undefined}
                  color="#2e0249"
                  size={130}
                  radius={1000}
                  className="img-user"
                  backgroundColor="#e1e1df"
                  handleError={() => setErrorAvatar(true)}
                />
                <h1>{`${myProfile?.first_name} ${myProfile?.last_name}`}</h1>
                <p>{`${sentenceCase(myProfile?.user_type || '')}`}</p>
              </Link>
              <div className="tab-active"></div>
            </Menu.Item>
          )}
          {myProfile?.user_type === UserType.AGENT && (
            <ConnectWalletButton
              active={active}
              account={account as string}
              chainId={chainId as number}
              connectMetamask={connectMetamask}
              disconnectMetamask={disconnectMetamask}
              copyToClipBoard={copyToClipBoard}
              isAddressCopied={isAddressCopied}
            />
          )}

          {userMenuItems[myProfile?.user_type as UserType].map((item) => {
            const toPath =
              item.to === RoutePaths.AGENT_REPORT && mailingListsCombine?.results[0]?.id
                ? `${item.to}/${mailingListsCombine?.results[0]?.id}`
                : item.to;
            let pathname = window.location.pathname;
            if (pathname !== '/') {
              pathname = pathname.replace(/\/$/, '');
            }
            const isActive = toPath?.includes(pathname);
            return (
              <Menu.Item
                key={toPath.replaceAll('/', '')}
                id={toPath}
                className={`${isActive ? 'ant-menu-item ant-menu-item-selected' : ''}`}
              >
                <Link to={toPath}>
                  <span className="fa-icon">{item.icon}</span>
                  {item.name}
                </Link>
                <div className="tab-active"></div>
              </Menu.Item>
            );
          })}
          <CreditAndHomeAmount />
          <ExitView />
        </Menu>
      </SimpleBar>
    </SideBarViewStyled.Sider>
  );
};

export default SideBarView;
